.calendar-container {
	@include breakpoint('m') {
		border-top-right-radius: $border-radius;
		border-bottom-right-radius: $border-radius;
	}

	@include breakpoint('m', 'max') {
		padding: 12px 0;
	}
}

#calendar, 
.trailer-calendar-preview {
	text-align: center;
	width: 100%;

	@include breakpoint('m') {
		border-top-left-radius: $border-radius;
	    border-bottom-left-radius: $border-radius;
	}
	
	.calendar-head {
		position: relative;
		display: block;
		margin-bottom: .5rem;
		
		.month-control {
			color: $gray;
			position: absolute;
			
			/*&.btn-prev,
			&.btn-next {
				width: 20px;
				height: 20px;

				@include breakpoint('m') {
					width: 26px;
					height: 26px;
				}
			}*/

			&.btn-prev {
				position: absolute;
				left: 0;
			}
			
			&.btn-next {
				position: absolute;
				right: 0;
			}
		}

		.calendar-title {
			@include fontSize(13px);
			@include lineHeight(26px);
			font-weight: bold;
		}
		
	}
	
	.day-name,
	.day {
		width: 14.28%;
	}
	
	.day-names {
	
		.day-name {
			color: $green;
		}
	}
	
	.day {
		margin: 2px 0;
		cursor: pointer;
	
		&.past, &.closed {
			cursor: not-allowed;
			
			> .day-number {
				opacity: .2;
			}
		}

		.day-number {
			font-style: normal;
			font-weight: 500;
			@include fontSize(11px);
			@include lineHeight(26px);
			display: inline-block;
		
			&.today {
				color: $white;
			}
		}
	}

}