.login-form-container {
    position: relative;
	background: $white;
	
	input {
		&:focus {
			outline: none;
		}
	}
	@include breakpoint('m') {
		border-radius: 10px;
		border-right: 25px solid $green;
		z-index: 9999;
	
		.right-container {
			z-index: 9999;
			border-right: 10px solid white;
			border-top-right-radius: 10px;
			border-bottom-right-radius: 10px;
		}
	}
}