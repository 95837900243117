.booking-confirmation {
	text-align: center;
	@include fontSize(15px);
	@include lineHeight(20px);

	h1.headline {
		@include fontSize(22px);
		@include lineHeight(27px);
		margin: 10px 0;
	}
}