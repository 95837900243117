form {
	
	label {
		display: block;
		color: $light-gray;
		@include fontSize(12px);
		@include lineHeight(16px);
	}

	.caption {
		@include fontSize(11px);
		@include lineHeight(13px);
	}

	input {
		background: $white;
		color: $green;
		border: 1px solid $light-gray;
		box-sizing: border-box;
		@include fontSize(15px);
		@include lineHeight(20px);
		border-radius: $border-radius;

		&::placeholder {
			color: $light-gray;
		}

		&:-ms-input-placeholder {
			color: $light-gray;
		}

		&::-ms-input-placeholder {
			color: $light-gray;
		}

		&[type="checkbox"] {
			display: inline-block;
			width: 14px;
			height: 14px;
			border-radius: $border-radius;
			margin-right: 8px;
		    vertical-align: middle;
		    border: 1px solid $light-gray;
		    cursor: pointer;

		    &:checked {
		    	background: $blue;
		    	border: 2px solid $light-gray;
		    }
		}

		/*&:invalid,*/ &.invalid {
			background: $white;
			border: 1px solid $danger;

			&::placeholder {
				color: $danger;
			}

			&:-ms-input-placeholder {
				color: $danger;
			}

			&::-ms-input-placeholder {
				color: $danger;
			}
		}
	}

	[contenteditable="true"], 
	input, 
	button,
	select,
	textarea {
	    width: calc(100% - 5px);
	}

	.circle-checkbox {
		display: inline-block;
		width: 14px;
		height: 14px;
		border-radius: 50%;
		margin-right: 8px;
		vertical-align: middle;
		border: 1px solid #E2E2E2;
		outline: none;
		cursor: pointer;
		position: relative;

		> input[type="checkbox"] {
			position: absolute;
		    top: 0;
		    left: 0;
		    display: inline-block;
		    width: 6px;
		    height: 6px;
		    transform: translate(50%, 50%);
		    border-radius: 50%;
		    border: none;
		    outline: none;
		    cursor: pointer;
		}

	}

	.custom-form-control {
		width: 100%;
	    height: 44px;
	    padding: 17px;
	}

	.btn {
		
		&[type="submit"] {
			/*width: 243px;*/
			width: 75%;
			height: 44px;
		}
	}

	.error {
		@include fontSize(17px);
		@include lineHeight(22px);
	}

	&.lightweight {

		input {
			border-radius: unset;

			&[type="checkbox"] {
				-webkit-appearance: none;
			    outline: none;
			}

			&[type="text"], &[type="time"],&[type="date"] {
			    background: transparent;
			    border: none;
			    border-bottom: 1px solid $light-gray;
			}

			/*&:invalid,*/ &.invalid {
				background: $white;
				border: none;
				border-bottom: 1px solid $danger;
			}

		}

		[contenteditable="true"], 
		input, 
		button,
		select,
		textarea {
		    &:focus {
		    	outline: none;
		    }
		}
	}

	.date-time-fields {
		input {
			width: calc(50% - 2px);
		}
	}
}