.nav-btn.fullwidth {
    margin: .25rem;
}

.trailer-title {
    color: $success;
}

.admin-booking-listing {

    * {
        /*color: $black !important;*/
    }
}

.edit-trailer-container {
    padding: 15px;

    input {
        border-radius: unset;
        border: 0.5px solid #E2E2E2;
    }
}

.trailer-preview-container {

    > div {
        margin: 1rem 0;
    }

    .trailer-preview {
        position: relative;
        border: 1px solid #E5E5E5;
        padding: 1rem;
        border-radius: $border-radius;
        height: 100%;

        img {
            width: 100%;
        }

        .create-trailer-icon {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            width: 75px;
        }

        .trailer-actions {
            margin-top: .5rem;
        }
    }
}

#add-blocked-day-btn, .blocked-day {
    display: inline-block;
    border: 1px solid #E7E7E7;
    box-sizing: border-box;
    border-radius: 5px;
    width: 100%;
    height: 50px;
    text-align: center;
    cursor: pointer;
    margin: 1rem 0;

    img {
        max-height: 100%;
        margin: 0 auto;
    }
}