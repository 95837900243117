.icon {
    background-repeat: no-repeat !important;
    display: inline-block;
    width: 13px;
    height: 13px;
	margin-right: 5px;
	cursor: pointer;

	&.chevron {
		background: url(../icons/chevron.svg);
		width: 8px;
		height: 13px;
	}

	&.chevron-left {
		background: url(../icons/chevron-left.svg);
		background-size: 20px 20px !important;
		width: 20x;
		height: 20px;

		@include breakpoint('m') {
			background-size: 25px 25px !important;
			width: 25px;
			height: 25px;
		}
	}

	&.chevron-right {
		background: url(../icons/chevron-right.svg);
		background-size: 20px 20px !important;
		width: 20x;
		height: 20px;

		@include breakpoint('m') {
			background-size: 25px 25px !important;
			width: 25px;
			height: 25px
		}
	}

	&.chevron-active {
		background: url(../icons/chevron-active.svg);
		width: 8px;
		height: 13px;
	}

	&.plus {
		background: url(../icons/plus.svg);
		background-size: 25px 25px;
		width: 25px;
		height: 25px;
	}
	
	&.cross {
		background: url(../icons/cross.svg);
		background-size: 25px 25px;
		width: 25px;
		height: 25px;
	}

	&.day {
		background: url(../icons/day-marker.svg);
		background-position: center !important;
		background-size: 20px 20px !important;
		width: 20px;
		height: 20px;
		
		@include breakpoint('m') {
			background-size: 25px 25px !important;
			width: 25px;
			height: 25px;
		}
	}

	&.few-remaining,
	&.limited-capacity {
		background: url(../icons/few-remaining.svg);
	}

	&.booked-out {
		background: url(../icons/booked-out.svg);
		cursor: not-allowed !important;
	}

	&.selected {
		background: url(../icons/selected.svg);
	}

	&.today {
		background: url(../icons/today.svg);
		color: $white;
	}

	&.cancel {
		background: url(../icons/cancel.svg);
		background-size: 16px 16px;
		width: 16px;
		height: 16px;
	}

	&.confirm {
		background: url(../icons/confirm.svg);
		background-size: 16px 16px;
		width: 16px;
		height: 16px;
	}

	&.confirmation,
	&.confirmed,
	&.booked,
	&.success {
		background: url(../icons/confirm.svg);
		background-size: 60px 60px;
		width: 60px;
		height: 60px;
	}

	&.cancellation,
	&.cancelled,
	&.failure {
		background: url(../icons/cancellation.svg);
		background-size: 60px 60px;
		width: 60px;
		height: 60px;
	}

	&.close {
		background: url(../icons/close.svg);
		background-size: 15px 15px;
		width: 15px;
		height: 15px;
	}

	&.booking {
		background: url(../icons/booking.svg);
		background-size: 25px 25px;
		width: 25px;
		height: 25px;
	}

}