@import './variables';
@import './mixins';
@import '../../node_modules/bootstrap/scss/bootstrap';
@import './admin';
@import './booking-confirmation';
@import './booking';
@import './bookings';
@import './buttons';
@import './icons';
@import './appointment-container';
@import './calendar';
@import './fonts';
@import './forms';
@import './footer';
@import './modal';
@import './slot-select';
@import './trailer-select';
@import './legal';
@import './login';

html,
body {
	font-family: 'Aktiv Grotesk';
	font-style: normal;
	font-weight: normal;
	background: $lightblue;
	min-height: 100vh;
	font-size: 10px; 

	@include breakpoint('m') {
		font-size: 12px; 
	}

	@include breakpoint('l') {
		font-size: 13px; 
	}

	@include breakpoint('xl') {
		font-size: 16px; 
	}
}

main {
	position: relative;
	min-height: 50vh;
	
	@include breakpoint('m') {
		padding-top: 24px;

		.booking-footer, .calendar-footer { 
			padding-bottom: 24px;
		}
	}
}

h1 {
	@include fontSize(24px);
	@include lineHeight(28px);
	text-align: center;
	color: $black;
	margin-bottom: 34px;
}

h2 {
	@include fontSize(22px);
	@include lineHeight(26px);
	margin: 15px 0;
}

h3 {
	@include fontSize(20px);
	@include lineHeight(22px);
	margin: 12px 0;
}

h4 {
	@include fontSize(18px);
	@include lineHeight(20px);
	margin: 8px 0;
}

strong.title {
	@include fontSize(13px);
	@include lineHeight(26px);
	font-weight: bold;
	display: block;
}

a:not(.btn) {

	&,
	&:active,
	&:hover,
	&:focus,
	&:visited {
		text-decoration: none;
		color: $dark-gray;
	}
}

table, 
tbody {
	width: 100%;
}

.border-radius-left {
	border-top-left-radius: $border-radius;
	border-bottom-left-radius: $border-radius;
}

.border-radius-right {
	border-top-right-radius: $border-radius;
	border-bottom-right-radius: $border-radius;
}

.pointer {
	cursor: pointer;
}

.hidden { 
    display: none;
}

.label-success,
.text-success h1 {
	color: $green;
}

.label-danger,
.text-danger h1 {
	color: $red;
}

.fullwidth {
    width: 100%;   
}

.control-button-container {
	position: fixed;
    right: 15px;
    top: 10px;
    z-index: 99999;
}

.fixed-centered {
	position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.spacer {
	height: .5rem;
}

#error-container {
	@include fontSize(10px);
	color: $danger;
}

.content-container {
    position: relative;
	min-height: 50vh;
	
	.content-centered {
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
	}

	@include breakpoint('m') {
		border-radius: 10px;

		.content-centered-v > * {
			position: absolute;
		    top: 50%;
		    left: 0;
		    /*right: calc(8.33333%);*/
		    right: 15px;
		    transform: translateY(-50%);
		}
	}
}

.logo-container {
	padding: 0 15px 0 0;

	.logo {
		width: 100%;
		height: auto;
		margin-bottom: 1rem;
	}
}

.dropzone {
	height: 100%; 

	input,
	.dz-button {
		display: none;
	}
}

.nopad {
	padding: 0;
}

.green-element {
	background: $green !important;
	color: $white;
	@include fontSize(15px);
	@include lineHeight(20px);
	border-radius: 10px;
	padding: 15px 22px;
	text-align: left;
	
	h1 {
		color: $white;
		text-align: left;
	}
}

@media (max-width: 767px) {
	.offset-sm-1 {
		margin-left: 0;
	}
}


/* Desktop only */
@include breakpoint('m') {
	.mobile-only {
		display: none;
	}

	.green-border {
		position: absolute;
		height: 100%;
		width: 45px;
		right: -25px;
		top: 0;
		z-index: 0;
		border-radius: 10px;
		border-right: 35px solid green;
	}
}

/* Mobile only */
@include breakpoint('m', 'max') {
	.desktop-only {
		display: none;
	}
	
	.mr-auto {
		margin-right: -15px !important;
	}
}