.footer {
    text-align: center;
    margin-top: .75rem;
    margin-bottom: .75rem;
	padding: 0 30px 0 15px;
    @include fontSize(15px);
    @include lineHeight(20px);

	&, a {
		color: #474747 !important
	}
	.footer-light {
		color: $dark-gray;
	}
	.footer-text {

		&:not(:last-child) {
			margin-right: 12px;
			@include breakpoint('m') {
				margin-right: 30px;
			}
		}
	}
	
	hr {
		color: #c4c4c4;
		width: 100%;
		margin-right: 15px;
	}
}