.btn {
	border-radius: $border-radius;

	&.inactive {
		background: $gray;
		color: $white;
	}
}

.btn-success {
	color: $white;

	&:hover {
		background: $lightgreen;
		border-color: $lightgreen;
	}
}

.btn-light {
	color: $white;

	&:hover {
		color: $white;
		background: $secondary;
		border-color: $secondary;
	}
}

.large-btn {
	@include fontSize(15px);
	@include lineHeight(30px);
	min-width: 90%;

	@include breakpoint('m', 'max') {
		width: 100%;
	}
}