.modal {
	position: absolute;
	z-index: 9999;

	.modal-dialog {
		position: absolute;
		height: 80vh;
		width: 80vw;
		left: 10vw;
		right: 10vw;
		max-width: unset;
    	align-items: normal;

		.modal-content {
			padding: 110px 38px;
			overflow: auto;
			
			@include breakpoint('m', 'max') {
    			padding: 50px 25px;
    		}
		}
	}

	.close-dialog {
		position: absolute;
	    right: 14px;
	    top: 14px;
	    cursor: pointer;
	    z-index: 9999;

	    &:focus {
	    	outline: none;
	    }
	}

}