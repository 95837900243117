#booking-form {

	@include breakpoint('m') {
		.row {
			margin-bottom: 32px;
		}
	}

	@include breakpoint('m', 'max') {
		input {
			margin-bottom: 16px;
		}
	}

}
