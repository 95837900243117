#slot-select {
	text-align: left;
	background: $green;
	border-radius: 0px 10px 10px 0px;
	height: 100%;
	
	.descriptor {
		display: inline-block;
		color: $white;
		font-style: normal;
		font-weight: 500;
		@include fontSize(12px);
		@include lineHeight(16px);
		margin-bottom: 30px;
		padding-right: 22px;
	}

	.slots-container {

		.slot {
			position: relative;
			display: block;
			color: $inactive-white;
			border-bottom: 1px solid $white;
			height: 56px;
			padding: 15px 22px 15px 0;
			cursor: pointer;


			&:first-child {
				border-top: 1px solid $white;
			}

			&:last-child {
				@include breakpoint('m', 'max') {
					border-bottom: none;
				}
			}

			&.inactive {
				opacity: 0.5;
				cursor: not-allowed;
			}

			.slot-text {
				margin-left: 31px;
			}

			.icon {
				position: absolute;
			}

		}

	}

}


@include breakpoint('m', 'max') {
	#slot-select {
		border-radius: unset;
		padding: 22px 0 0 22px; /* Improve */
	}
}