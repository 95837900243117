@mixin breakpoint($breakpoint, $direction: $media-direction) {
  @if map-has-key($breakpoints, $breakpoint) {
    @media (#{$direction}-width: #{map-get($breakpoints, $breakpoint)}) {
      @content;
    }
  }

  // Use px if no other unit is specified
  @else if unitless($breakpoint) {
    $breakpoint: $breakpoint + 0px;

    @media (#{$direction}-width: $breakpoint) {
      @content;
    }
  }
}

@function calculateRem($size) {
  $remSize: $size / 16px;
  @return #{$remSize}rem;
}

@function calculateRemValue($size) {
  $remSize: $size / 16px;
  @return $remSize * 1rem;
}

@mixin fontSize($size) {
  font-size: $size; // Fallback in px
  font-size: calculateRem($size);
}

@mixin lineHeight($size) {
  line-height: $size; // Fallback in px
  line-height: calculateRem($size);
}