// Variables
//
// Variables should follow the `$component-state-property-size` formula for
// consistent naming. Ex: $nav-link-disabled-color and $modal-content-box-shadow-xs.

// Color system

$white:    #fff;
$gray-100: #f8f9fa;
$gray-200: #e9ecef;
$gray-300: #dee2e6;
$gray-400: #ced4da;
$gray-500: #adb5bd;
$gray-600: #6c757d;
$gray-700: #495057;
$gray-800: #343a40;
$gray-900: #212529;
$dark-gray: #C4C4C4;
$light-gray: #CECECE;
$black:    #000;

$grays: ();
// stylelint-disable-next-line scss/dollar-variable-default
$grays: map-merge(
  (
    "100": $gray-100,
    "200": $gray-200,
    "300": $gray-300,
    "400": $gray-400,
    "500": $gray-500,
    "600": $gray-600,
    "700": $gray-700,
    "800": $gray-800,
    "900": $gray-900
  ),
  $grays
);

$blue:    #005CA1;
$indigo:  #6610f2;
$purple:  #6f42c1;
$pink:    #e83e8c;
$red:     #EB5757;
$orange:  #fd7e14;
$yellow:  #ffc107;
$green:   #009901;
$lightgreen: #99CC01;
$teal:    #20c997;
$cyan:    #17a2b8;
$gray:	  #E2E2E2;
$inactive-white: #FFF;
$lightblue: #DEE2E6;

$colors: ();
// stylelint-disable-next-line scss/dollar-variable-default
$colors: map-merge(
  (
    "blue":       $blue,
    "indigo":     $indigo,
    "purple":     $purple,
    "pink":       $pink,
    "red":        $red,
    "orange":     $orange,
    "yellow":     $yellow,
    "green":      $green,
    "teal":       $teal,
    "cyan":       $cyan,
    "white":      $white,
    "gray":       $gray-600,
    "gray-dark":  $gray-800
  ),
  $colors
);

$primary:       $green;
$secondary:     $gray-600;
$success:       $green;
$info:          $cyan;
$warning:       $yellow;
$danger:        $red;
$light:         #C4C4C4;
$dark:          $gray-800;

// Borders
$border-radius: 10px;

// Breakpoints
$breakpoints: (
  'default':            '',
  'xs':                 480px,
  's':                  667px,
  'm':                  992px,
  'l':                  1024px,
  'xl':                 1680px,
);
$media-direction: min;

$grid-breakpoints: (
  xs: 0,
  sm: 576px,
  md: 992px,
  lg: 1200px,
  xl: 1280px
);

$container-max-widths: (
  sm: 540px,
  md: 960px,
  lg: 1178px,
  xl: 1220px
);