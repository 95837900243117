.bookings-container {
	max-height: calc(50vh - 30px);
	margin-top: 15px;
	overflow-y: auto;

	.booking-listing { 
		/*height: 48px;*/
		@include fontSize(13px);
	    @include lineHeight(48px);
	    margin-bottom: 8px;
	    background: $white;
	    color: $green;

	    &.cancelled {

	    	&, * {
	    		color: $danger;
	    	}

	    	a:hover {
	    		color: #ff7e7e;
	    	}
	    }
	    
	    a {
	    	color: $green;

	    	&:hover {
	    		color: $lightgreen;
	    	}
	    }

	    span {

	    	&:not(:last-child) {
	    		margin-right: 31px;
	    	}

	    	&.highlight {
	    		@include fontSize(17px);
	    	}
	    }

	    > div {
	    	background: $white;

	    	&:not(:first-child) {
	    		border-left: 3px solid $gray;
	    	}
	    }

		.change-status-btn {
			cursor: pointer;
			margin-top: 16px;
		}
	}

}