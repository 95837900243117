.appointment-container {
	background: $green;

	& > div {
		background: $white;
		
		@include breakpoint('m') {
			&:nth-child(2) {
				border-top-right-radius: $border-radius;
				border-bottom-right-radius: $border-radius;
			}
		}
	}

	#calendar {
		.day {
			margin: 14px 0;
		}

		.calendar-head {
			margin-bottom: 1.5rem;
		}

		.month-control {
			&.btn-prev {
				transform: translateX(100%);
			}

			&.btn-next {
				transform: translateX(-100%);
			}
		}
	}

	#booking-form-container {
		margin-top: 15px;
	}

	@include breakpoint('m', 'max') {
		#booking-form-container {
			padding: 12px 0;
		}
	}

}