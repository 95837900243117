.privacy-container {
	@include fontSize(12px);
	@include lineHeight(26px);
	
	h1 {
		text-align: left;
		color: $black;
		font-style: normal;
	    font-weight: bold;
	    @include fontSize(22px);
		@include lineHeight(28px);
	}
}