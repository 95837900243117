.trailer-select-container {
    position: relative;
    background: $white;
    overflow-x: auto;
    padding: 15px;

    @include breakpoint('m') {
          z-index: 9999;
          max-height: calc(100vh - 188px);
    }

    .trailer {
        box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.15);
        border-radius: 5px;
        padding: 12px;
        margin: 15px 0;

        .trailer-data {

            > div {
                margin-bottom: .5rem;
            }
        }

        .trailer-image {
            img {
                width: 100%;
            }
        }

        .trailer-specs-table {
            border: 0;

            tr > td:nth-child(2) {
                text-align: right;
            }
        }

        .trailer-pricing {
            table {
                td:nth-child(2) {
                    text-align: right;
                }
            }
        }
    }
}

.white-fade {
    @include breakpoint('m') {
        display: block;
        width: 100%;
        height: 80px;
        background: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, $white 100%);
        position: absolute;
        bottom: 0;
        z-index: 999;
    }
}